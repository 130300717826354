import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ComAppBar from '../common/ComAppBar';
import { cognito_idp_signout } from '../homescreen/Utils.js'
import { Link } from 'react-router-dom';
import { dslthemeblue } from "../styles/theme";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AppButton = ({path, label, icon, external, ...rest}) => {
    const commonProps = {
        variant: "contained",
        style: {
            padding: 10,
            margin: 10,
            height: "auto"
        },
        size: "large"
    };

    return external ? (
        <Button {...rest}
            {...commonProps} 
            href={path}
            >
            {icon} {label}
        </Button>
    ) : (
        <Button {...rest}
            {...commonProps} 
            component={Link}
            to={path}
            >
            {icon} {label}
        </Button>
    )
};

AppButton.propTypes = {
    path: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.node,
    external: PropTypes.bool
}

class AppsPage extends Component {

    handleLogout = () => {
		this.props.userHasAuthenticated(false);
		this.props.history.push("/");
		cognito_idp_signout();
    }

    appSelComponents = (
        <React.Fragment>
            <ComAppBar
                title = "LTA CASE RETRIEVAL PORTAL (BETA)"
                handleLogout = {this.handleLogout}
             />
            <br />
            <br />
            <br />
            <div style={{
                display: "flex",
                justifyContent: "center",
                width: "50%",
                margin: "auto"
                }}>
                <AppButton 
                    label="Case Retrieval" 
                    path="/home" 
                    icon={<FontAwesomeIcon icon={["fab","wpforms"]} style={{margin: 5}} />} />
                <AppButton 
                    label="Real Time Dashboard" 
                    path="https://www.dslmonitoring.net"
                    external 
                    icon={<FontAwesomeIcon icon="tachometer-alt" style={{margin: 5}} />} />
            </div>
            <p style={{fontSize: "0.9em", color: "gray"}}>Please do not refresh the page, as the user session will be closed</p>
        </React.Fragment>
    )

	render() {
		return(
			<div>
				<MuiThemeProvider theme={dslthemeblue}>
                    {this.appSelComponents}
				</MuiThemeProvider>
            </div>
        )
	}
	
}

AppsPage.propTypes = {
    userHasAuthenticated: PropTypes.func.isRequired,
    history: PropTypes.any.isRequired
}

export default AppsPage;
