import React from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import pick from 'lodash.pick';

function flatten_data(data) {
    // For now, flatten any object into its JSON String
    return data.map(row => {
        const newobj = Object.assign({}, row);
        for (let attr in newobj) {
            if (typeof newobj[attr] === "object") {
                newobj[attr] = JSON.stringify(newobj[attr]).replace(/,/g, " ");
            }
        }
        return newobj;
    });
}

export function CSVDownloadLink(props) {
    let data = flatten_data(props.data);

    const style = {
        "float" : "right", 
        "top" : "-35px", 
        "right" : "90px", 
        "position" : "relative"
    };

    const headers = [
        "caseId",
        "bicycleId",
        "violationType",
        "firstDetectionSrc",
        "firstDetection",
        "lng_bso",
        "lat_bso",
        "officerId",
        "caseSubmissionTimeStamp",
        "lng_actual",
        "lat_actual",
        "validated",
        "validations",
        "invalidations",
        // "remarks",
        // "picture_paths"
    ];

    data = data.map(d => pick(d, headers));

    return <CSVLink filename="export.csv" headers={headers} data={data} style={style}>Export to CSV</CSVLink>
}

CSVDownloadLink.propTypes = {
    data: PropTypes.array.isRequired
}