import React, { Component } from "react";
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import { MuiThemeProvider } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { dslthemeblue } from "../styles/theme";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';
import UM_HOST from '../umHost.js';

class ComAppBar extends Component {
    
    constructor(props) {
        super(props);
        this.title = props.title;
        this.handleLogout = props.handleLogout || null;
    }

    iconStyle = {
        color: 'white',
        marginLeft: 5
    };

    appbarright_icon = (
        <FontAwesomeIcon icon="sign-out-alt" style={this.iconStyle} />
    )

    appbarright = (
        <Button
            variant="flat"
            color="inherit"
            onClick={() => this.handleLogout()}
            style={{position: "absolute", right: "10px"}}
            >
            Logout {this.appbarright_icon}
        </Button>
    )

    render() {
        return(
            <div>
                <MuiThemeProvider theme={dslthemeblue}>
                    <AppBar position="static" backgroundColor="blue">
                        <Toolbar color="blue" backgroundColor="blue">
                            <Typography variant="headline"
							    color="inherit"
                                style={{margin: "auto"}}
                                >
                                <Link to={`/apps`} style={{color:"white", textDecoration:"none"}}>{this.title}</Link>
                            </Typography>
                            {this.handleLogout && window.sessionStorage.getItem("isAdmin")==="true" && <Link to={`/createUser`} style={{marginRight:"130px"}} class="appbar">Create User</Link>} 
                            {this.handleLogout && <a class="appbar" href={UM_HOST+"/change_password"}> Change Password </a>}
                            {this.handleLogout ? this.appbarright : null}
                        </Toolbar>
                    </AppBar>
                </MuiThemeProvider>
            </div>
        )
    }
}

ComAppBar.propTypes = {
    title: PropTypes.string.isRequired,
    handleLogout: PropTypes.func
}

export default ComAppBar;
