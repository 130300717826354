import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { MuiThemeProvider } from '@material-ui/core/styles';
import ComAppBar from './common/ComAppBar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Link, withRouter } from 'react-router-dom';
import { dslthemeblue } from "./styles/theme.js"
import * as log from 'loglevel';
import bgimg from "./images/login_v1.1.jpg";
import APPVERSION from './version';
import { Redirect } from 'react-router-dom'
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import jwtDecode from 'jwt-decode';
import UM_HOST from './umHost.js';
import UMBE_HOST from './umbeHost.js';
import API_BASE_URL from './host.js';

const style = {
  margin: 15,
};

function LoginComponents(props) {
  
  const textareastyle = {
    backgroundColor: "rgba(106,175,209,0.5)",
    border: "1px solid #4A4A4A",
    borderRadius: "10px",
    display: "inline-block",
    padding: "10px"
  };

  return (
    <div>
      <ComAppBar
        title="LTA CASE RETRIEVAL PORTAL (BETA)" />
      <div>
        <br />
        <p>
          <font size="5"> Please Login </font>
        </p>
      </div>
      <form className='loginForm'>
        <div>
          <div style={textareastyle}>
            <TextField
              placeholder="Enter your Username"
              color="primary"
              style={style}
              label="Username"
              onChange={
                (event) => props.credHandler({ username: event.target.value })}
              onKeyPress={(event) => event.key == "Enter" && props.clickHandler(event)}
            />
            <br />
            <TextField
              type="password"
              placeholder="Enter your Password"
			        color="primary"
              style={style}
              label="Password"
              onChange={
                (event) => props.credHandler({ password: event.target.value })}
              onKeyPress={(event) => event.key == "Enter" && props.clickHandler(event)}
            />
          </div>
          <br />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={style}
              component={Link}
              to="/apps"
              onClick={(event) => props.clickHandler(event)}>
                Submit
              </Button>
              <a class="default" href={UM_HOST+"/reset_password"}>Forgot password</a>
        </div>
      </form>
      <p style={{position:"absolute", bottom: "2em", textDecoration: "underline", fontSize: "0.8em", margin: "0 auto", left: "49%", backgroundColor: "rgba(255,255,255,0.5)"}}>
        {"v" + APPVERSION}
      </p>
    </div>
  )
}

LoginComponents.propTypes = {
  credHandler: PropTypes.func.isRequired,
  clickHandler: PropTypes.func.isRequired
}

class Login extends Component {
  constructor(props){
    super(props);
    this.userGroup="undefined";
    this.toggleBlocking = this.toggleBlocking.bind(this);
    this.state={
      username:'',
      password:'',
      blocking: false,
    }

  }

  toggleBlocking() {
    this.setState({blocking: !this.state.blocking});
  }
  componentDidMount() {
//	this.login("mo_guangquan", "testing123")
//	.then(tokens => {
		
//        log.debug(tokens);
//        window.sessionStorage.setItem("idToken", JSON.stringify(tokens.id_token));
//		var today = new Date();
//		var expire = new Date();
//		var nDays = 1;
//		expire.setTime(today.getTime() + 3600000*24*nDays);
//		document.cookie = "idToken="+escape(JSON.stringify(tokens.id_token)) + ";expires="+expire.toGMTString();
        //add isAdmin to session based on user's id token
//        window.sessionStorage.setItem("isAdmin", "false");
//		alert("Login successful");
//        this.userGroup=jwtDecode(JSON.stringify(tokens.id_token))['cognito:groups'];
//        if(typeof this.userGroup !== 'undefined'
//          && this.userGroup.includes('DSL_Admin_User_Group')){
//          window.sessionStorage.setItem("isAdmin", "true");
//        }
        //console.log("login authentication passed.")
//        this.props.userHasAuthenticated(true, tokens);
//        this.props.history.push("/home");
//	})
//    .catch(error => {
	//remove block ui when error occurs
//	alert("Login Failed");
//    });
    this.props.userHasAuthenticated(false);
  }

  login(username, password) {
    const loginurl = "https://" + API_BASE_URL + "/auth";
    return fetch(loginurl, {
      method: "OPTIONS",
      mode: "cors"
    })
    .then(response => response.url) 
    .then(url =>
      fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({
          username: username,
          password: password
        })
      })
    )
    .then(response => response.json()) // parses response to JSON
    .then(jsobj => {
      if ("error" in jsobj) {
        throw new Error(jsobj["error"]);
      } else {
        return jsobj;
      }
    });
  }

  handleClick = event => {
    //activate block ui on submit login form
    this.toggleBlocking();
    event.preventDefault();

    this.login(this.state.username, this.state.password)
      .then(tokens => {
        log.debug(tokens);

        //add user's id token to session
        window.sessionStorage.setItem("idToken", JSON.stringify(tokens.id_token));

        //add isAdmin to session based on user's id token
        window.sessionStorage.setItem("isAdmin", "false");
        this.userGroup=jwtDecode(JSON.stringify(tokens.id_token))['cognito:groups'];
        if(typeof this.userGroup !== 'undefined'
          && this.userGroup.includes('DSL_Admin_User_Group')){
          window.sessionStorage.setItem("isAdmin", "true");
        }
        //console.log("login authentication passed.")
        this.props.userHasAuthenticated(true, tokens);

        //check user password policy pass
	/*
        const checkAccountAccessUrl = UMBE_HOST+"/checkAccess";
        fetch(checkAccountAccessUrl, {
          method: 'POST',
          mode: "cors",
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: this.state.username
          }),
        }).then((response) => response.json()).then((res) => {
          //remove block ui upon check access response
          this.toggleBlocking();

          if (res.allowAccess === "true"){
            //normal login process
            this.props.history.push("/apps");
          }
          else {
            //redirect to change password page
            window.location.href = UM_HOST+"/change_password";
          }
        })
        .catch((error) => {
          //remove block ui when error occurs
          this.toggleBlocking();
          console.error(error);
        });
        */
        this.props.history.push("/apps");
      })
      .catch(error => {
        //remove block ui when error occurs
        this.toggleBlocking();
        log.error(`Fetch Error =\n`, error);
        alert("Login Failed");
      });
  }


  handleCredentials = (cred) => {
    this.setState(cred)
  }

  render() {
    return (
      <BlockUi tag="div" blocking={this.state.blocking}>
      <MuiThemeProvider theme={dslthemeblue}>
        <div style={{backgroundImage: `url(${bgimg})`, backgroundRepeat:"no-repeat", width:"100vw", height:"100vh"}}>
          <LoginComponents
            credHandler={this.handleCredentials}
            clickHandler={this.handleClick} />
        </div>
      </MuiThemeProvider>
      </BlockUi>
    );
  }
}

Login.propTypes = {
  userHasAuthenticated: PropTypes.func.isRequired,
  history: PropTypes.object // history object
}

export default withRouter(Login);
