import AWS from "aws-sdk";
import * as log from 'loglevel';

export default async function cognito_idp_signin(usertoken) {
    const idpool_id = 'ap-southeast-1:af30e972-60f9-45d9-80af-6ab1158bb16f';
    const userpool_id = 'ap-southeast-1_5xnA4ss3W';
    AWS.config.region = 'ap-southeast-1';

    const cidp = `cognito-idp.${AWS.config.region}.amazonaws.com/${userpool_id}`;
    const login_obj = {};
    login_obj[cidp] = usertoken.id_token;
    
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: idpool_id,
        Logins: login_obj
    });

    await AWS.config.credentials
        .getPromise()
        .then(function () {
                var id = AWS.config.credentials.identityId;
                log.debug('Cognito Identity ID ' + id);
            },
            function(err) {
                log.debug('Error on creating AWS Resource credentials')
                log.debug(err);
            }
        );
}

export const cognito_idp_signout = () => {
    if (AWS.config.credentials) {
        AWS.config.credentials.clearCachedId();
        log.debug("AWS Credentials cleared.");
    }
}
