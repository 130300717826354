import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const style = {
  container: {
    position: "fixed",
    top: "50%",
    left: "50%"
  },
  refresh: {
    display: 'inline-block',
    position: 'relative',
  },
};

export const Spinner = () => (
  <div style={style.container}>
    <CircularProgress
      color="primary"
      style={style.refresh}
    />
  </div>
);