import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

// Used https://material.io/tools/color to find closest to #0065A3

export const dslthemeblue = createMuiTheme({
  palette: {
    primary: {
      main: "#063D57",
    }
  },
});