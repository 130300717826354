import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Case.css';

import { MuiThemeProvider } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

import ComAppBar from "../common/ComAppBar";
import { dslthemeblue } from "../styles/theme"
import { getLast6Locations, getImagePaths, cognito_idp_signout, actOnCase } from "../homescreen/Utils";
import * as log from 'loglevel';
import DecodeToken from "../common/decode-verify-jwt";
import Lightbox from '../common/react-image-lightbox-rotate/index';
import 'react-image-lightbox/style.css';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import CaseIcon from '@material-ui/icons/Assignment';
import EoIcon from '@material-ui/icons/Face';
import VeIcon from '@material-ui/icons/Motorcycle';
import OperatorIcon from '@material-ui/icons/Business';
import CalendarIcon from '@material-ui/icons/DateRange';
import ClockIcon from '@material-ui/icons/AccessTime';
import LocationIcon from '@material-ui/icons/MyLocation';
import AlertIcon from '@material-ui/icons/Warning';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import PeopleIcon from '@material-ui/icons/People';
import InfoIcon from '@material-ui/icons/Info';
import EventNoteIcon from '@material-ui/icons/EventNote';


import { Spinner } from '../common/Spinner';
import {operatorNames} from '../common/NameMaps';

function getOperatorName(bicycleId) {
	return operatorNames[bicycleId.substring(0,3)] || "";
}

function fillData(data) {
	if ("caseSubmissionTimeStamp" in data) {
		[data["caseDate"], data["caseTime"]] =
			data["caseSubmissionTimeStamp"].split("T");
		data["caseTime"] = data["caseTime"].substring(0, 8);
	}
	if ("firstDetection" in data) {
		[data["firstCaseDate"], data["firstCaseTime"]] =
			data["firstDetection"].split("T");
		data["firstCaseTime"] = data["firstCaseTime"].substring(0, 8);
		if ("caseSubmissionTimeStamp" in data && "caseId" in data && data["caseId"].indexOf("U-") >=0) {
			[data["firstCaseDate"], data["caseTime"]] =
				data["caseSubmissionTimeStamp"].split("T");
			data["firstCaseTime"] = data["caseTime"].substring(0, 8);
			data["lat_bso"] = data["lat_actual"];
			data["lng_bso"] = data["lng_actual"];
        }
		if (data["firstCaseDate"].indexOf("1970") == 0) {
			data["firstCaseDate"] = "N/A";
			data["firstCaseTime"] = "N/A";
		}
	}
	if ("firstDetectionSrc" in data) {
		if (data["firstDetectionSrc"] == "1"){
			data["firstDetectionSrcDisplay"] = "Licensee Information";
		}
		if (typeof data["firstDetectionSrc"] === 'string' || data["firstDetectionSrc"] instanceof String){
			if (data["firstDetectionSrc"].indexOf("0") == 0){
				if (data["firstDetectionSrc"].length > 1){
					data["firstDetectionSrcDisplay"] = "ENF Officer " + data["firstDetectionSrc"].substring(1);
				}else{
					data["firstDetectionSrcDisplay"] = "ENF Officer ";
				}
			}
		}else{
			data["firstDetectionSrcDisplay"] = "ENF Officer ";
		}
		if ("caseId" in data && data["caseId"].indexOf("U-") == 0){
			data["firstDetectionSrcDisplay"] = "ENF Officer " + data["officerId"];
		}
	}

	data.validations = data.validations || [];
	data.invalidations = data.invalidations || [];
	data.hireable = data.hireable || false;

	data.operator = getOperatorName(data.bicycleId)

	return data;
}

export class Casescreen extends Component {

	//To be passed from the table
	constructor(props) {
		super(props);
		this.state = fillData(props.data)
		this.state.locations = [];
		this.state.loading = false;
		this.state.uservalidated = false;
		this.state.uservalue = "select";
		DecodeToken({token: props.tokens.id_token}, null, this.setUser);
		this.state.lightboxopen = false;
		this.state.lightboxindex = 0;
		getImagePaths(this.state.picture_paths, this.props.tokens)
			.then(imgpaths => this.setState({signedpicturepaths: imgpaths}));
		getLast6Locations(this.state.bicycleId, this.state.firstDetection, this.state.caseSubmissionTimeStamp, props.tokens.id_token)
			.then(res => this.setState({locations: res}));
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	setUser = (err, claims) => {
		if (err) {
			log.error(err);
		} else {
			log.debug(claims);
			this.setState({user: claims["cognito:username"]});
		}
	}

	photoClickHandler = (i) => {
		this.setState({
			lightboxopen: true,
			lightboxindex: i
		});
	}

	getImages(imagepaths, imagets, imagestyle) {
		if(typeof imagepaths !== "undefined") {
			return imagepaths.map((imagepath, i) => {
				return (
					<Card key={i} onClick={() => this.photoClickHandler(i)} style={{cursor: "zoom-in"}}>
						<CardMedia
							image={imagepath}
							style={imagestyle} />
						<CardContent>
							<Typography variant="subheading">
								{(i + 1) + ": " + imagets[i].replace(/\.\d+$/, "")}
							</Typography>
						</CardContent>
					</Card>
				)
			});
		}
	}

	getValidationHistory(validations, invalidations) {
		const getListItem = ({id, timestamp}) =>
			<ListItem>{`${id}: ${timestamp}`}</ListItem>

		return (
			<React.Fragment>
				<List>
					<ListSubheader>Validations</ListSubheader>
					{validations.map(getListItem)}
				</List>
				<Divider />
				<List>
					<ListSubheader>Invalidations</ListSubheader>
					{invalidations.map(getListItem)}
				</List>
			</React.Fragment>
		)
	}

	hasValidated = (validations, invalidations) => {
		const all_actions = validations.concat(invalidations);
		return all_actions.some(({id}) => id === this.state.user);
	}

	handleLogout = () => {
		this.props.userHasAuthenticated(false);
		this.props.history.push("/");
		cognito_idp_signout();
	}

	selectionChange = (event) => {
		this.setState({uservalue: event.target.value})
	}

	submitHandler = () => {
		// Call case validation endpoint to add result of user (in)validation
		// Need to have user details to submit the case

		const actionStr = this.state.uservalue == "validate" ? "Validat" : "Invalidat";
		
		this.props.presubmitHandler(`${actionStr}ing case id: ${this.state.caseId}`);

		actOnCase(this.state.uservalue, this.state, this.props.tokens)
			.then((res) => log.debug(res))
			.then(() => this.props.submitHandler(`Case id: ${this.state.caseId} ${actionStr}ed`));

		this.setState({uservalidated: true})
		
		this.closeHandler();
	}

	closeHandler = () => {
		this.props.history.goBack()
	}

	render() {

		var locationStyleRed = {
			color: "red",
		};
		var locationStyle0 = {
			width: "100%",
		};

		var locationStyle1 = {
			width: "20%",
		};

		var locationStyle2 = {
			width: "15%",
			borderStyle: "solid",
			borderWidth: "1px",
		};

		var locationStyle3 = {
			width: "100%",
			borderStyle: "solid",
			borderWidth: "1px",
		};
		
		var listTextStyle = {
			fontWeight: "bolder",
			fontSize: "140%",
		};

		var borderStyle = {
			borderStyle: "solid",
			borderWidth: "1px",
			borderColor: "#f2f2f2",
			marginTop: "2%",
			marginLeft: "4%",
			marginRight: "4%",
		};

		var validateStyle = {
			display: "flex",
			alignItems: "center",
			padding: "2%",
		};

		var buttonStyle = {
			margin: "0px 10px"
		};

		var titleStyle = {
			fontWeight: 400,
			textDecoration: "underline",
			color: "black",
			paddingTop: "8px"
		};

		var photoscontainer = { 
			marginLeft: "1%", 
			marginRight: "1%",
			paddingTop: "1%",
			paddingBottom: "3%", 
			display: "grid", 
			gridTemplateColumns: "repeat(3, 1fr)",
			gridAutoRows: "30vh",
			gridRowGap:"5vh",
			justifyItems: "center"
		}

		var photoStyle = {
			height: "80%",
			width: "25vw"
		}

		const selIsDisabled = ["Invalid","Valid"].includes(this.state.validated) ||
			this.hasValidated(this.state.validations, this.state.invalidations) || this.state.uservalidated;

		const {lightboxopen, 
				lightboxindex, 
				signedpicturepaths:images, 
				picture_timestamps:imagets} = this.state;


		const googlemapsapib = "https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyChoraT5NXFy_rWwzx7X2-IEVH3v-ej8Xw&size=500x400&zoom=18&markers=label:B%7C";
		const googlemapsapia = "https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyChoraT5NXFy_rWwzx7X2-IEVH3v-ej8Xw&size=500x400&zoom=18&markers=label:A%7C";
		//const googlemapsapi = "https://www.google.com/maps/search/?api=1&query=";

		const get_hireable_icon = (hireable) => (
			hireable ? <CheckIcon color="primary"/> : <CloseIcon color="error"/>
		)

		return (
			<div>
				<ComAppBar handleLogout={this.handleLogout} title={
					<span className='app-bar-title'>
						<span> Case Screen for </span>
						<span className="app-bar-title">{this.state.caseId}</span>
					</span>
				} />
			        {(typeof this.state.locations !== 'undefined' && typeof this.state.locations.status !== 'undefined') ? null : <Spinner /> }
				<MuiThemeProvider theme={dslthemeblue}>
					<div style={borderStyle}>
						<Paper elevation={3}>
							<List>
								<ListItem>
									<div>
										<ListItemIcon>
											<CaseIcon />
										</ListItemIcon>
										<span> Case ID: </span>
										<span style={listTextStyle}> {this.state.caseId} </span>
									</div>
								</ListItem>
								<ListItem>
									<div>
										<ListItemIcon>
											<AlertIcon />
										</ListItemIcon>
										<span> Violation Type: </span>
										<span style={listTextStyle}> {this.state.violationType} </span>
									</div>
								</ListItem>
								<ListItem>
									<div>
										<ListItemIcon>
											<EoIcon />
										</ListItemIcon>
										<span > Enforcement Officer ID: </span>
										<span style={listTextStyle}> {this.state.officerId} </span>
									</div>
								</ListItem>

								<ListItem>
									<div>
										<ListItemIcon>
											<VeIcon />
										</ListItemIcon>
										<span> UIC: </span>
										<span style={listTextStyle}> {this.state.bicycleId} </span>
									</div>
								</ListItem>

								<ListItem>
									<div>
										<ListItemIcon>
											<OperatorIcon />
										</ListItemIcon>
										<span> Operator: </span>
										<span style={listTextStyle}> {this.state.operator} </span>
									</div>
								</ListItem>
							</List>
						</Paper>
					</div>

					<div style={borderStyle}>
						<Paper elevation={3}>
							<Typography style={titleStyle} variant="display1" gutterBottom>
								1st Detection
							</Typography>
							<List>
								<ListItem>
									<div>
										<ListItemIcon>
											<InfoIcon />
										</ListItemIcon>
										<span> Source: </span>
										<span style={listTextStyle}> {this.state.firstDetectionSrcDisplay} </span>
									</div>
								</ListItem>
								<ListItem>
									<div>
										<ListItemIcon>
											<EventNoteIcon />
										</ListItemIcon>
										<span> Observation: </span>
										<span style={listTextStyle}> Vehicle spotted, outside of designated zone </span>
									</div>
								</ListItem>
								<ListItem>
									<div>
										<ListItemIcon>
											<CalendarIcon />
										</ListItemIcon>
										<span> Date: </span>
										<span style={listTextStyle}> {this.state.firstCaseDate} </span>
									</div>
								</ListItem>
								<ListItem>
									<div>
										<ListItemIcon>
											<ClockIcon />
										</ListItemIcon>
										<span> Time: </span>
										<span style={listTextStyle}> {this.state.firstCaseTime} </span>
									</div>
								</ListItem>
								<ListItem>
										<ListItemIcon>
											<LocationIcon />
										</ListItemIcon>
									        <div style={locationStyle1}>
			                                                        <div style={locationStyle0}>
										<span> Location (B): </span><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
			                                                        </div>
			                                                        <div style={locationStyle0}>
										{/* Lat Lng format tested to work with Google Maps and OneMap*/}
										<a rel="noreferrer noopener" target="_blank" href={(typeof this.state.locations !== "undefined" && typeof this.state.locations.mapURL1 !== "undefined" && this.state.locations.mapURL1 !== '')?this.state.locations.mapURL1:googlemapsapib + this.state.lat_bso + "," + this.state.lng_bso}>({this.state.lat_bso}, {this.state.lng_bso})</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
										</div>
									</div>

<div style={locationStyle2}><div style={locationStyle3}>Location 1 +2hrs</div><div style={locationStyle3}>{this.state.locations.last6f1}&nbsp;&nbsp;</div></div><div style={locationStyle2}><div style={locationStyle3}>Location 2 +4hrs</div><div style={locationStyle3}>{this.state.locations.last6f2}&nbsp;&nbsp;</div></div><div style={locationStyle2}><div style={locationStyle3}>Location 3 +6hrs</div><div style={locationStyle3}>{this.state.locations.last6f3}&nbsp;&nbsp;</div></div>
			

								</ListItem>
								<ListItem>
										<ListItemIcon>
											<PeopleIcon />
										</ListItemIcon>
			                                                        <div style={locationStyle1}>
                                                                                <div style={locationStyle0}>
										<span> Hireable: </span>
                                                                                </div>
                                                                                <div style={locationStyle0}>
										{get_hireable_icon(this.state.hireable)}
									        </div>
									        </div>
			<div style={locationStyle2}><div style={locationStyle3}>Location 4 +8hrs</div><div style={locationStyle3}>{this.state.locations.last6f4}&nbsp;&nbsp;</div></div><div style={locationStyle2}><div style={locationStyle3}>Location 5 +10hrs</div><div style={locationStyle3}>{this.state.locations.last6f5}&nbsp;&nbsp;</div></div><div style={locationStyle2}><div style={locationStyle3}>Location 6 +12hrs</div><div style={locationStyle3}>{this.state.locations.last6f6}&nbsp;&nbsp;</div></div>
								</ListItem>
							</List>
						</Paper>
					</div>

					<div style={borderStyle}>
						<Paper elevation={3}>
							<Typography style={titleStyle} variant="display1" gutterBottom>
								2nd Detection
							</Typography>
							<List>
								<ListItem>
									<div>
										<ListItemIcon>
											<InfoIcon />
										</ListItemIcon>
										<span> Source: </span>
										<span style={listTextStyle}> {this.state.officerId} </span>
									</div>
								</ListItem>
								<ListItem>
									<div>
										<ListItemIcon>
											<EventNoteIcon />
										</ListItemIcon>
										<span> Observation: </span>
										<span style={listTextStyle}> {this.state.caseType} </span>
									</div>
								</ListItem>
								<ListItem>
									<div>
										<ListItemIcon>
											<CalendarIcon />
										</ListItemIcon>
										<span> Date: </span>
										<span style={listTextStyle}> {this.state.caseDate} </span>
									</div>
								</ListItem>
								<ListItem>
									<div>
										<ListItemIcon>
											<ClockIcon />
										</ListItemIcon>
										<span> Time: </span>
										<span style={listTextStyle}> {this.state.caseTime} </span>
									</div>
								</ListItem>
								<ListItem>
                                                                                <ListItemIcon>
                                                                                        <LocationIcon />
                                                                                </ListItemIcon>
                                                                                <div style={locationStyle1}>
                                                                                <div style={locationStyle0}>
                                                                                <span>Location (A)</span><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                </div>
                                                                                <div style={locationStyle0}>
                                                                                {/* Lat Lng format tested to work with Google Maps and OneMap*/}
                                                                                <a rel="noreferrer noopener" target="_blank" href={(typeof this.state.locations !== "undefined" && typeof this.state.locations.mapURL2 !== "undefined" && this.state.locations.mapURL2 !== '')?this.state.locations.mapURL2:googlemapsapia + this.state.lat_actual + "," + this.state.lng_actual}>({this.state.lat_actual}, {this.state.lng_actual})</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                                </div>
                                                                                </div>
<div style={locationStyle2}><div style={locationStyle3}>Location 1 -2hrs</div><div style={locationStyle3}>{this.state.locations.last6s1}&nbsp;&nbsp;</div></div><div style={locationStyle2}><div style={locationStyle3}>Location 2 -4hrs</div><div style={locationStyle3}>{this.state.locations.last6s2}&nbsp;&nbsp;</div></div><div style={locationStyle2}><div style={locationStyle3}>Location 3 -6hrs</div><div style={locationStyle3}>{this.state.locations.last6s3}&nbsp;&nbsp;</div></div>

			                                        </ListItem>
								<ListItem>
			                                                        <ListItemIcon>
                                                                                        <PeopleIcon />
                                                                                </ListItemIcon>
                                                                                <div style={locationStyle1}>
                                                                                <div style={locationStyle0}>
                                                                                <span> Hireable: </span>
                                                                                </div>
                                                                                <div style={locationStyle0}>
                                                                                {get_hireable_icon(this.state.hireable)}
                                                                                </div>
                                                                                </div>

			<div style={locationStyle2}><div style={locationStyle3}>Location 4 -8hrs</div><div style={locationStyle3}>{this.state.locations.last6s4}&nbsp;&nbsp;</div></div><div style={locationStyle2}><div style={locationStyle3}>Location 5 -10hrs</div><div style={locationStyle3}>{this.state.locations.last6s5}&nbsp;&nbsp;</div></div><div style={locationStyle2}><div style={locationStyle3}>Location 6 -12hrs</div><div style={locationStyle3}>{this.state.locations.last6s6}&nbsp;&nbsp;</div></div>
								</ListItem>
							</List>
						</Paper>
					</div>

					{/* Need to ensure that multiple pictures can be handled using an external function call */}
					<div style={Object.assign({}, borderStyle, {marginTop: "0px"})}>
						<Paper elevation={3}>
							<Typography style={titleStyle} variant="display1" gutterBottom>
								Case Pictures for 2nd Detection
							</Typography>
							<div style={photoscontainer}>
								{this.getImages(images, imagets, photoStyle)}
							</div>
							
								Legend: A - Actual location; B - 1st detected location

						</Paper>
					</div>

					<div style={borderStyle}>
						<Paper elevation={3}>
							<Typography style={titleStyle} variant="display1" gutterBottom>
								Validation History
							</Typography>
							Validation Status: {this.state.validated}
							{this.getValidationHistory(this.state.validations, this.state.invalidations)}
						</Paper>
					</div>

					{/* Confirmation before validation is submitted*/}
					<div style={Object.assign({}, borderStyle, {marginBottom: "3%"})}>
						<Paper style={validateStyle} elevation={6} >
							<div style={{fontSize: "1.2em", fontWeight: "bold"}}>Mark Case as: </div>
							<Select 
								value={this.state.uservalue} 
								onChange={this.selectionChange}
								autoWidth={false} 
								style={{flexGrow: 1}}
								disabled={selIsDisabled}
								>
								<MenuItem value="select" disabled>- Select -</MenuItem>
								<MenuItem value="validate">Valid, the case details are correct</MenuItem>
								<MenuItem value="invalidate">Invalid, the case details are not sufficient</MenuItem>
							</Select>
							<Button style={buttonStyle} color="primary" 
								disabled={this.state.uservalue === "select" || selIsDisabled}
								onClick={this.submitHandler}>
								Submit 
							</Button>
							<Button style={buttonStyle} onClick={this.closeHandler}>
								Close
							</Button>
						</Paper>
					</div>
				</MuiThemeProvider>
				{lightboxopen && (
					<Lightbox
						imageTitle={(lightboxindex + 1) + ": " + imagets[lightboxindex]}
						mainSrc={images[lightboxindex]}
						nextSrc={images[(lightboxindex + 1) % images.length]}
						prevSrc={images[(lightboxindex + images.length - 1) % images.length]}
						onCloseRequest={() => this.setState({ lightboxopen: false })}
						onMovePrevRequest={() =>
							this.setState({
								lightboxindex: (lightboxindex + images.length - 1) % images.length,
							})
						}
						onMoveNextRequest={() =>
							this.setState({
								lightboxindex: (lightboxindex + 1) % images.length,
							})
						}
					/>
				)}
			</div>
		)
	}
}

Casescreen.propTypes = {
	data: PropTypes.shape({
		caseId: PropTypes.string.isRequired,
		officerId: PropTypes.string.isRequired,
		bicycleId: PropTypes.string.isRequired,
		caseType: PropTypes.string.isRequired,
		caseSubmissionTimeStamp: PropTypes.string.isRequired,
		validated: PropTypes.string.isRequired
	}),
	userHasAuthenticated: PropTypes.func.isRequired,
	history: PropTypes.any.isRequired,
	tokens: PropTypes.object.isRequired,
	submitHandler: PropTypes.func.isRequired,
	presubmitHandler: PropTypes.func
}

export default Casescreen;
