import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { DATERANGELIMIT } from './Utils.js';
import headerascimage from './images/header_asc.PNG';
import headerdescimage from './images/header_desc.PNG';

export default class HintDialog extends React.Component {
    state = {
        open: true,
    };

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    okbutton = <Button
        variant="flat"        
        color="primary"
        onClick={this.handleClose}>
        OK
    </Button>

    okdontshowbutton = <Button
        variant="flat"
        color="primary"
        onClick={() => {
            this.setDontShow();
            this.handleClose();
        }}>
        Disable hints
    </Button>

    isDontShow = () => window.localStorage.getItem("nohints") === "true"

    setDontShow = () => window.localStorage.setItem("nohints", "true")

    actiontext = (text) => <span style={{fontWeight: "bold"}}>{text}</span>

    render() {
        const headerimagestyle = {
            verticalAlign: "text-top",
            marginLeft: "20px"
        };

        return (
            this.isDontShow() ? null :
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                >
                <DialogTitle>
                    User Guide
                </DialogTitle>
                <DialogContent>
                    <DialogContentText style={{"textAlign": "left"}}>
                        <p>{this.actiontext("Search")} for cases between a <em>start date</em> and <em>end date</em> (max {DATERANGELIMIT} day range).</p>
                        <p>{this.actiontext("Filter")} cases using the text boxes under the headers.<br />
                            <span style={{"paddingLeft": "1em"}}>Adding &quot;!&quot; in front inverts the filter</span>
                        </p>
                        <p>{this.actiontext("Sort")} the data by clicking on the headers and hold shift to multi-sort!</p>
                        <ul>
                            <li style={{margin:"10px 0px"}}>Ascending: <img style={headerimagestyle} src={headerascimage} alt="Ascending indicator" /></li>
                            <li style={{margin:"10px 0px"}}>Descending: <img style={headerimagestyle} src={headerdescimage} alt="Ascending indicator"/></li>
                        </ul>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {[this.okbutton, this.okdontshowbutton]}
                </DialogActions>
            </Dialog>
        )
    }
}

