import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { dslthemeblue } from "../../styles/theme.js";

export default class SimpleDialog extends React.Component {
    render() {
        const bmap = this.props.buttonmap;

        const actions = Object.keys(bmap).map(
            (key, index) => <Button
                variant="flat"
                key={key}
                color="primary"
                autoFocus={index == 0}
                onClick={bmap[key]} >
                    {key}
                </Button>
            );
        
        return <MuiThemeProvider theme={dslthemeblue}>
            <Dialog
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                open={this.props.open}>
                <DialogTitle>
                    {this.props.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {this.props.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {actions}
                </DialogActions>
            </Dialog>
        </MuiThemeProvider>
    }
}

SimpleDialog.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    buttonmap: PropTypes.object,
    open: PropTypes.bool,
};

SimpleDialog.defaultProps = {
    open: false
}