import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ComAppBar from '../common/ComAppBar';
import { cognito_idp_signout } from '../homescreen/Utils.js'
import { Link } from 'react-router-dom';
import { dslthemeblue } from "../styles/theme";
import TextField from '@material-ui/core/TextField';
import bgimg from "../images/login_v1.1.jpg";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import UMBE_HOST from '../umbeHost.js';

const fetch_url = UMBE_HOST+'/create'
const style = {
  margin: 15,
};
const textareastyle = {
    backgroundColor: "rgba(106,175,209,0.5)",
    border: "1px solid #4A4A4A",
    borderRadius: "10px",
    display: "inline-block",
    padding: "10px"
  };

class CreateUser extends Component {

    constructor(props){
        super(props);
        this.state = {
          displayMessage: "",
          blocking: false,
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.refreshForm = this.refreshForm.bind(this);
    }
    
    toggleBlocking() {
      this.setState({blocking: !this.state.blocking});
    }

    handleLogout = () => {
		this.props.userHasAuthenticated(false);
		this.props.history.push("/");
		cognito_idp_signout();
    }

    appSelComponents = (
        <React.Fragment>
            <ComAppBar
                title = "LTA CASE RETRIEVAL PORTAL (BETA)"
                handleLogout = {this.handleLogout}
             />
            <br />
            <br />
            <br />
            <div style={{
                display: "flex",
                justifyContent: "center",
                width: "50%",
                margin: "auto"
                }}>
                
            </div>
        </React.Fragment>
    )
    
    refreshForm(e){
        document.getElementById("form_create").reset();
    }
    
    async onSubmit(e) {
      this.toggleBlocking();
      this.setState({
        displayMessage: ""
      })
  
      e.preventDefault();
  
      if (document.forms[0].elements["username"].value === ""){
        this.setState({
          displayMessage: "No username provided"
        })
      } else if (document.forms[0].elements["email"].value === ""){
        this.setState({
          displayMessage: "No email provided"
        })
      } else if ( fetch_url === 'http://localhost:3001/create' && (document.forms[0].elements["email"].value.split('@')[1] === "lta.gov.sg")){
        this.setState({
          displayMessage: "Currently do not create accounts with lta emails"
        })
      } else {
        await fetch(fetch_url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authentication': window.sessionStorage.getItem("idToken") 
          },
          body: JSON.stringify({
            username: document.forms[0].elements["username"].value,
            email: document.forms[0].elements["email"].value,
          }),
        }).then((response) => response.json())
          .then((js) => {
            this.setState({
              displayMessage: js.message
            })
  
            if (this.state.displayMessage.substring(0,7) === "Success"){
              this.refreshForm();
            }

            this.toggleBlocking();
          })
          .catch((error) => {
            this.toggleBlocking();
            console.error(error);
          });
        }
    }
    

	render() {
		return(
			<div>
        <BlockUi tag="div" blocking={this.state.blocking}>
          <MuiThemeProvider theme={dslthemeblue}>
            <div style={{backgroundImage: `url(${bgimg})`, backgroundRepeat:"no-repeat", width:"100vw", height:"100vh"}}>
              {this.appSelComponents}
              <div>
                <br />
                <p>
                  <font size="5"> Create Account </font>
                </p>
              </div>
              <form
                  className="form_create"
                  id="form_create"
                  action={this.props.action}
                  method={this.props.method}
                  onSubmit={this.onSubmit}>
                  <div>
                    <div style={textareastyle}>
                      <TextField
                        type="username"
                        placeholder="Enter the Username"
                        color="primary"
                        style={style}
                        name="username"
                        label="Username"
                      />
                      <br />
                      <TextField
                        type="email"
                        placeholder="Enter the Email"
                        color="primary"
                        style={style}
                        name="email"
                        label="Email"
                      />
                      {((this.state.displayMessage.substring(0,7) === "Success") && <p style={{color: "green"}}>{this.state.displayMessage}</p>)}
                      {(!(this.state.displayMessage.substring(0,7) === "Success") && <p style={{color: "red"}}>{this.state.displayMessage}</p>)}

                    </div>
                    <br />
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={style}
                      >
                        Submit
                    </Button>

                  </div>
              </form>
            </div>
          </MuiThemeProvider>
        </BlockUi>
      </div>
    )
	}
	
}

CreateUser.propTypes = {
    userHasAuthenticated: PropTypes.func.isRequired,
    history: PropTypes.any.isRequired
}

export default CreateUser;
