import {JwksClient as jwksClient} from './node-jwks-rsa-master/src/JwksClient';
import jwt from 'jsonwebtoken';
import * as log from 'loglevel';

const region = 'ap-southeast-1';
const userpool_id = "ap-southeast-1_5xnA4ss3W";
const app_client_id = "10fucbr71h3j6gv2as6i10ilo";
const keys_url = 'https://cognito-idp.' + region + '.amazonaws.com/' + userpool_id + '/.well-known/jwks.json';

export default function DecodeToken(event, context, callback) {
    const token = event.token;

    const client = new jwksClient({
        jwksUri: keys_url
    });

    function getKey(header, callback) {
        client.getSigningKey(header.kid, function (err, key) {
            var signingKey = key.publicKey || key.rsaPublicKey;
            callback(null, signingKey);
        });
    }

    jwt.verify(token, getKey, {aud: app_client_id}, callback);
}