import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "./border.css";
import CBReactTablePagination from "./CBReactTablePagination";
import * as log from 'loglevel';

//Need to add this

/*

// When any Td element is clicked, we'll log out some information
<ReactTable
  getTdProps={(state, rowInfo, column, instance) => {
    return {
      onClick: (e, handleOriginal) => {
        log.debug("A Td Element was clicked!");
        log.debug("it produced this event:", e);
        log.debug("It was in this column:", column);
        log.debug("It was in this row:", rowInfo);
        log.debug("It was in this table instance:", instance);

        // IMPORTANT! React-Table uses onClick internally to trigger
        // events like expanding SubComponents and pivots.
        // By default a custom 'onClick' handler will override this functionality.
        // If you want to fire the original onClick handler, call the
        // 'handleOriginal' function.
        if (handleOriginal) {
          handleOriginal();
        }
      }
    };
  }}
/>

*/


// Eventually, will need to receive data from a server
// Find from here: https://react-table.js.org/#/story/server-side-data



function CustomTable(props) {
  //makes the filters CASE INSENSITIVE
  const filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id
    let logicalOp = a => a;
    let matchValue = filter.value.toLowerCase();
    if (filter.value.substring(0,1) == "!") {
      // Invert the outputs
      logicalOp = a => !a;
      matchValue = matchValue.substring(1);
    }
    
    return row[id] !== undefined ? 
      logicalOp(String(row[id].toLowerCase()).startsWith(matchValue)) : true
  };

  const getTdProps = (state, rowInfo, column, instance) => {
    return {
      onClick: (e, handleOriginal) => {
        /* Need to link to the case in the row*/
        log.debug("Row clicked:", rowInfo);
        if (rowInfo) {
          let rowCase = rowInfo.original;
          props.rowOnClick(rowCase);
        }

        // IMPORTANT! React-Table uses onClick internally to trigger
        // events like expanding SubComponents and pivots.
        // By default a custom 'onClick' handler will override this functionality.
        // If you want to fire the original onClick handler, call the
        // 'handleOriginal' function.
        if (handleOriginal) {
          handleOriginal();
        }
      }
    };
  }

  return (
    <div>
      <ReactTable
        getTdProps={getTdProps}
        defaultFilterMethod={filterMethod}
        filterable={true}
        data={props.data}
        columns={[
          {
            Header: "IDs",
            columns: [
              {
                Header: "Case",
                accessor: "caseId"
              },
              {
                Header: "EO User",
                accessor: "officerId"
              },
              {
                Header: "UIC",
                accessor: "bicycleId"
              }
            ]
          },

          {
            Header: "Case Type",
            columns: [
              {
                Header: "Violation Type",
                accessor: "violationType",
                width: 250
              }
            ]
          },

          {
            Header: 'Case Details',
            columns: [
              {
                Header: "Case Date",
                id: "case_date",
                accessor: d => d.caseSubmissionTimeStamp.split("T")[0]
              },
              {
                Header: "Case Time",
                id: "case_time",
                accessor: d => d.caseSubmissionTimeStamp.split("T")[1].substring(0, 8)
              },
              {
                Header: "Validated?",
                accessor: "validated"
              }
            ]
          }
        ]}
        defaultSorted={[
          {
            id: 'case_date',
            desc: true,
          },
          {
            id: 'case_time',
            desc: true,
          },
          {
            id: 'officerId'
          }
        ]}
        defaultPageSize={10}
        className="-striped -highlight"
        PaginationComponent={CBReactTablePagination}
      />
    </div>
  );
}

CustomTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    caseId: PropTypes.string.isRequired,
    officerId: PropTypes.string.isRequired,
    bicycleId: PropTypes.string.isRequired,
    caseType: PropTypes.string.isRequired,
    violationType: PropTypes.string.isRequired,
    caseSubmissionTimeStamp: PropTypes.string.isRequired,
    validated: PropTypes.string.isRequired
  })),
  rowOnClick: PropTypes.func.isRequired
}

export default CustomTable;