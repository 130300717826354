
//Authored by Wen Rui, Intern under Shawn Kwek from LTA ITCD. Contact: wenrui@berkeley.edu

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';

ReactDOM.render(
    <App />,
  document.getElementById('root')
);
