import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import {DatePicker} from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import * as log from 'loglevel';
import {addDays, roundDayDown, roundDayUp, DATERANGELIMIT} from './Utils';

// Error props are unused
const DatePickerTemplate = (props) => 
    <DatePicker {...props} 
        autoOk={true}
        maxDate={addDays(new Date(), 1)}
        format="YYYY-MM-DD"
        style={{
            margin: "0px 5px",
        }}
    />

DatePickerTemplate.propTypes = {
    error: PropTypes.bool,
    errorcol: PropTypes.string
}

const theme = createMuiTheme();

class DateRangePicker extends React.Component {    
    constructor(props) {
        super(props);
        this.state = {
            searchEnabled: true,
            searchTip: "",
            endDate: roundDayUp(new Date())
        };
        this.state.startDate = roundDayDown(addDays(this.state.endDate, -(DATERANGELIMIT - 1)));
        this.style.searchTip.color = theme.palette.secondary.main;        
    }

    style = {
        searchTip: {
            fontSize: "0.9em",
            height: "0.9em"
        }
    }

    setStartDate = (startDate) => this.setState({startDate: roundDayDown(startDate)}, this.dateCheck)
    setEndDate = (endDate) => this.setState({endDate: roundDayUp(endDate)}, this.dateCheck)

    dateCheck = () => {        
        const dayinms = 1000 * 60 * 60 * 24;
        let {startDate: st, endDate: en, searchEnabled, searchTip} = this.state;
        if (st > en) {
            searchEnabled = false;
            searchTip = "Start date comes after end date"
        } else if ((en - st) > (DATERANGELIMIT * dayinms)) {
            searchEnabled = false;
            searchTip = `Date range greater than ${DATERANGELIMIT} days`
        } else {
            // within range
            searchEnabled = true;
            searchTip = ""
        }
        this.setState({searchEnabled, searchTip})
    }

    searchTip = (props) => 
        <div style={this.style.searchTip}>{props.tip}</div>

    callSearchHandler = () => {
        this.props.searchHandler(this.state.startDate, this.state.endDate);
    }

    render() {
        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <div style={{
					color: "blue",
                    margin: "15px 0px", 
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end"
                    }}>
                    <DatePickerTemplate 
					    color="primary"
                        label="Start Date" 
                        value={this.state.startDate}
                        onChange={this.setStartDate}
                        />
                    <DatePickerTemplate 
					    color="primary"
                        label="End Date" 
                        value={this.state.endDate}
                        onChange={this.setEndDate} 
                        disableFuture
                        />
                    <Button variant="contained"
                        color="primary"
                        disabled={!this.state.searchEnabled} 
                        onClick={this.callSearchHandler} >
                        Search
                    </Button>
                </div>
                <this.searchTip tip={this.state.searchTip} />
            </MuiPickersUtilsProvider>
        )
    }

    componentDidMount() {
        log.debug("Date Range Picker mounted, calling a refresh of data");
        this.callSearchHandler();
    }
}

DateRangePicker.propTypes = {
    searchHandler: PropTypes.func.isRequired,
    muiTheme: PropTypes.shape()
}

export default DateRangePicker;
