import "./Index.css";
import * as log from 'loglevel';
import API_BASE_URL from "../host.js";

// Data from AWS
//----------------------------------------------------

import AWS from "aws-sdk";

export {cognito_idp_signout} from './AWSCognitoIDP';

export const DATERANGELIMIT = 31;

AWS.config.update({
    region: "ap-southeast-1",
});

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

function roundDayDown(date) {
  const new_date = new Date(date);
  new_date.setHours(0);
  new_date.setMinutes(0);
  new_date.setSeconds(0);
  new_date.setMilliseconds(0);
  return new_date;
}

function roundDayUp(date) {
  const new_date = new Date(date);
  new_date.setHours(23);
  new_date.setMinutes(59);
  new_date.setSeconds(59);
  new_date.setMilliseconds(999);
  return new_date;
}

export {addDays, roundDayDown, roundDayUp};

const violationMap = {
	"Vehicle spotted, outside of designated zone": "Failure to remove improperly parked bicycle in public places within stipulated time period",
	"Vehicle spotted within designated zone, no violation": "Nil",
	"Vehicle not found": "Nil",
	"No UIC sticker": "Failure to affix unique identification code",
	"UIC not unique": "Failure to equip functioning Bicycle Location Device that is maintained in good working condition",
	"Actual location more than 50m": "Failure to provide Data Feed to the Authority",
	"Operating with invalid/expired license": "Operating with invalid/expired license",
	"Operating without license": "Operating without license",
	"Operator has license, but vehicle not on master list (KIV)": "Operator has license, but vehicle not on master list (KIV)"
}

function setViolationType(caseArr) {
  for (let i of caseArr) {
    i.violationType = violationMap[i.caseType];
  }

  return caseArr;
}

function getCaseData(start_date, end_date, tokens, handler) {
  // Convert from time zone aware to time zone unaware
  start_date = new Date(start_date.getTime() - (start_date.getTimezoneOffset() * 60000));
  end_date = new Date(end_date.getTime() - (start_date.getTimezoneOffset() * 60000));
  const params_string = `start_date=${start_date.getTime()}&end_date=${end_date.getTime()}`;
  const api_url = API_BASE_URL;
  const get_cases_endpoint = 
    //`https://bi2teip6y8.execute-api.ap-southeast-1.amazonaws.com/uat/lta-dsl-bike-uat/v1/cases?${params_string}`;
    //`https://6i563yteoj.execute-api.ap-southeast-1.amazonaws.com/prod/cases?${params_string}`;
    `https://${api_url}/cases?${params_string}`;

  return fetch(get_cases_endpoint, {
    method: "OPTIONS",
    mode: "cors"
  })
  .then(response => response.url)
  .then(url => 
    fetch(`${url}`, {
      method: "GET",
      mode: "cors",
      headers: {
        'Authorization': 'Bearer ' + tokens.id_token
      }
    })
  )
  .then(response => response.json()) // parses response to JSON
  .then(setViolationType)
  .then(handler)
}

function getLast6Locations(bike, date1, date2, tokens) {
	  // Convert from time zone aware to time zone unaware
	  const get_cases_endpoint =
		`https://txrsysljm1.execute-api.ap-southeast-1.amazonaws.com/prod/get-location-map`;

	  return fetch(get_cases_endpoint, {
		method: "OPTIONS",
		mode: "cors"
	  })
	  .then(response => response.url)
	  .then(url =>
		fetch(`${url}`, {
		  method: "POST",
		  mode: "cors",
		  headers: {
			'Authorization': 'Bearer ' + tokens.id_token
		  },
		  body: JSON.stringify({
			  bicycleId: bike,
			  date1: date1,
			  date2: date2
		  })
		})
	  )
	  .then(response => response.json()) // parses response to JSON
}

export { getLast6Locations };

function actOnCase(action, case_item, tokens) {
  const params_string = `action=${action}&casetimestamp=${case_item.caseSubmissionTimeStamp}`;
  const api_url = API_BASE_URL;
  const update_case_endpoint = 
    //`https://bi2teip6y8.execute-api.ap-southeast-1.amazonaws.com/uat/lta-dsl-bike-uat/v1/case/${case_item.caseId}?${params_string}`;
    `https://${api_url}/case/${case_item.caseId}?${params_string}`;

  return fetch(update_case_endpoint, {
    method: "OPTIONS",
    mode: "cors"
  })
  .then(response => response.url)
  .then(url => 
    fetch(`${url}?${params_string}`, {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        'Authorization': 'Bearer ' + tokens.id_token
      }
    })
  )
  .then(response => response.json()) // parses response to JSON
}

export {actOnCase};

// Dummy data
//---------------------------------------------------------

const range = len => {
  const arr = [];
  for (let i = 0; i < len; i++) {
    arr.push(i);
  }
  return arr;
};

function randomDate(start, end) {
  var date = new Date(+start + Math.random() * (end - start));
  return date;
}

function randomViolationState() {
  var states = ["Unlicensed Operator","Outside Parking Zone >4 hours","No UniqueID", "No working tracker"];
  var chance = Math.random();
    if (chance < 0.25) {
      return states[0];
    }
    if (chance >= 0.25 && chance < 0.50) {
      return states[1];
    }
    if (chance >= 0.50 && chance < 0.75) {
      return states[2];
    }
    else {
      return states[3]; 
    }
}

function randomState() {
  var states = ["Valid","Not Valid","Not Checked"];
  var chance = Math.random();
    if (chance < 0.33) {
      return states[0];
    }
    if (chance >= 0.33 && chance < 0.66) {
      return states[1];
    }
    else {
      return states[2]; 
    }
}

const newReport = () => {
  const statusChance = Math.random();
  return {
    caseID: "LTA" + String(Math.floor(100000 + Math.random() * 900000)),
    EOID: "EO" + String(Math.floor(1000 + Math.random() * 9000)),
    VeID: "VE" + String(Math.floor(10000 + Math.random() * 90000)),
    violation: randomViolationState(),
    date: randomDate(new Date(2018,1,1), new Date(2018,12,31)).toLocaleDateString("en-US"),
    time: randomDate(new Date(2018,1,1), new Date(2018,12,31)).toLocaleTimeString("en-US"),
    valid: randomState()
  };
};

function getMockData(len = 200) {
  log.debug(range(len));

  var toCheck = range(len).map(() => {
    return {
      ...newReport(),
      //children: range(10).map(newPerson),
    };
  });
  log.debug(toCheck);
  return toCheck;
}

export { getCaseData as getCaseData };

// Get images stored in AWS
function getImagePaths(imagepaths, tokens) {
  //const endpoint = "https://bi2teip6y8.execute-api.ap-southeast-1.amazonaws.com/uat/lta-dsl-bike-uat/v1/case_image_paths";
  const endpoint = "https://" + API_BASE_URL + "/case_image_paths";

  return fetch(endpoint, {
    method: "OPTIONS",
    mode: "cors"
  })
  .then(response => response.url)
  .then(url => 
    fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        'Authorization': 'Bearer ' + tokens.id_token
      },
      body: JSON.stringify(imagepaths)
    })
  )
  .then(response => response.json()) // parses response to JSON
}

export { getImagePaths };
