import React, {Component} from "react";
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Login from '../Login.js';
import PrivateRoute from "./PrivateRoute.js";
import Homescreen from "../homescreen/Home";
import AppSelection from "../appselection/AppsPage";
import CreateUser from "../usermanagement/CreateUser";
import IdleTimer from 'react-idle-timer';
import {cognito_idp_signout} from '../homescreen/Utils.js'
import Dialog from '../common/dialogs/SimpleDialog';
import * as log from "loglevel";

const IDLE_TIME_IN_MIN = 55;
const GRACE_TIME_IN_MIN = 60 - IDLE_TIME_IN_MIN;
// const IDLE_TIME_IN_MIN = 0.1;
// const GRACE_TIME_IN_MIN = 0.1;
const MS_IN_MIN = 1000 * 60;

export default class Routes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showIdle: false
		};
	}

	handleLogout = () => {
		this.props.childProps.userHasAuthenticated(false);
		cognito_idp_signout();
		window.location = "/";
	}

	onIdle = () => {
		if (this.props.childProps.isAuthenticated) {
			log.debug("User's idle period has passed threshold")
			this.logoutTimer = setTimeout(this.handleLogout, GRACE_TIME_IN_MIN * MS_IN_MIN);
			// Have to use our own modals, as the confirm method will freeze the timer
			this.setState({showIdle: true});
		}
	}

	closeDialog = () => {
		this.setState({showIdle: false});
	}

	handlers = {
		Continue: () => {this.closeDialog(); clearTimeout(this.logoutTimer)},
		Cancel: () => {this.closeDialog(); this.handleLogout()}
	}

	render() {
		const timeoutTitle = "Closing idle session";
		const timeoutMsg = `Press OK within ${GRACE_TIME_IN_MIN} minutes to continue session`;
		return (
			<Router>
				<IdleTimer
					onIdle={this.onIdle}
					timeout={MS_IN_MIN * IDLE_TIME_IN_MIN}>
					<Route exact path="/" render={() => (<Login {...this.props.childProps} />)} />
					<PrivateRoute path="/apps" component={AppSelection} props={this.props.childProps} />
					<PrivateRoute path="/home" component={Homescreen} props={this.props.childProps} />
					<PrivateRoute path="/createUser" component={CreateUser} props={this.props.childProps} />
					<Dialog open={this.state.showIdle} title={timeoutTitle} message={timeoutMsg} buttonmap={this.handlers} />
				</IdleTimer>
			</Router>
		)
	}
}

Routes.propTypes = {
	childProps: PropTypes.object.isRequired,
}