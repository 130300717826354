import React, { Component  } from 'react';
import './App.css';
import Routes from './routing/Routes.js';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faSignOutAlt, faTachometerAlt, faCog } from '@fortawesome/free-solid-svg-icons'
import { faWpforms } from '@fortawesome/free-brands-svg-icons'
import * as log from 'loglevel';

import Cookie from 'js-cookie';

library.add(faSignOutAlt, faTachometerAlt, faWpforms, faCog)

log.setDefaultLevel(process.env.NODE_ENV === "development" ? "debug" : "info");

const EARLY_REFRESH_IN_SEC = 60;

export class App extends Component {
  constructor(props){
    super(props);
    this.state={
      tokens: undefined,
      isAuthenticated: false,
    }
    this.setCookieDefaults()
  }

  setCookieDefaults() {
    Cookie.defaults.domain = "dslmonitoring.net";
    Cookie.defaults.expires = 1/24;
    Cookie.defaults.path = "/";
  }

  storeCookie(jsobj) {
    for (let i in jsobj) {
      Cookie.set(i, jsobj[i])
    }
  }

  clearCookie() {
    for (let i in Cookie.get()) {
      Cookie.remove(i)
    }
  }

  userHasAuthenticated = (authenticated, tokens) => {
    this.setState({isAuthenticated: authenticated, tokens});

    if (!authenticated) {
      if (typeof this.state.tokens !== "undefined") {
        this.userLogout();
        this.clearCookie();
      }
    } else {
      // Prepare to refresh token
      window.setInterval(
        this.refreshTokens, 
        (tokens.expires_in_secs - EARLY_REFRESH_IN_SEC) * 1000);
      
      // Store cookie for shared use with dashboard
      this.storeCookie(tokens);
    }
  };

  userLogout = () => {
    log.debug("Logging out of Cognito");
    const logouturl = "/auth/logout";
    return fetch(logouturl, {
      method: "OPTIONS",
      mode: "cors"
    })
    .then(response => response.url)
    .then(url => 
      fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": `Bearer ${this.state.tokens.id_token}` 
        },
        body: JSON.stringify({
          access_token: this.state.tokens.access_token
        })
    }))
    .then(response => response.json()) // parses response to JSON
    .then(jsobj => log.debug(jsobj))
    .catch(err => log.error(err))
  }

  // Ideally, we should have a module dedicated to authentication tasks
  refreshTokens = () => {
    log.debug("Refreshing token with Cognito");
    const refreshurl = "/auth/auth_refresh";
    return fetch(refreshurl, {
      method: "OPTIONS",
      mode: "cors"
    })
    .then(response => response.url)
    .then(url => 
      fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": `Bearer ${this.state.tokens.id_token}` 
        },
        body: JSON.stringify({
          refresh_token: this.state.tokens.refresh_token
        })
      })
    )
    .then(response => response.json()) // parses response to JSON
    .then(jsobj => {
      log.debug(jsobj);
      if ("error" in jsobj) {
        throw new Error(jsobj["error"]);
      } else {
        this.userHasAuthenticated(true, jsobj);
      }
    })
    .catch(err => log.error(err))
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      tokens: this.state.tokens
    };

    return <Routes childProps={childProps} />;
  }

}

export default App;
