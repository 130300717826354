import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import DateRangePicker from './DateRangePicker';
import ComAppBar from '../common/ComAppBar';
import CustomTable from './Table.js';
import './Home.css';
import { dslthemeblue } from "../styles/theme.js";
import { getCaseData, cognito_idp_signout } from "./Utils";
import * as log from 'loglevel';
import { Route } from "react-router-dom";
import PrivateRoute from '../routing/PrivateRoute';
import Casescreen from "../casescreen/Case";
import { Spinner } from '../common/Spinner';
import HintDialog from './HintDialog';
import pick from 'lodash.pick';
import { CSVDownloadLink } from './CSVDownloadLink';
import { Button } from '@material-ui/core';

//need to check if the search query is valid data <X amt of characters, not found in database etc.

class Homescreen extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			curdata: null,
			loading: false,
			laststartdate: null,
			lastenddate: null,
			snackbar: {
				open: false,
				message: ""
			}
		}
	}

	updateDataHandler = (data) => {
		this.setState({data: data, loading: false});
	}

	refreshData = (startdate, enddate) => {
		log.debug("Refreshing data for " + startdate + " to " + enddate);
		startdate = startdate || this.state.laststartdate;
		enddate = enddate || this.state.lastenddate

		this.setState({
			loading: true, 
			laststartdate: startdate, 
			lastenddate: enddate
		});
		getCaseData(startdate, enddate, this.props.tokens, this.updateDataHandler);
	}
	
	handleLogout = () => {
		this.props.userHasAuthenticated(false);
		this.props.history.push("/");
		cognito_idp_signout();
	}

	rowOnClickHandler = (rowdata) => {
		this.setState({curdata: rowdata});
		this.props.history.push(this.props.match.url + "/case/" + rowdata.caseId);
	}

	closeButton = (
		<Button color="secondary" 
			size="small"
			onClick={() => this.closeSnackbarHandler()}>
			Close
		</Button>
	);

	tablePage = ({match}) => (
		<div style={{display: match.isExact ? "inline" : "none"}}>
			<MuiThemeProvider theme={dslthemeblue}>
				{/* Used to edit the application bar */}
				<React.Fragment>
					<ComAppBar
						title="LTA CASE RETRIEVAL PORTAL (BETA)"
						handleLogout={this.handleLogout}
					/>
					<HintDialog />
					<DateRangePicker searchHandler={this.refreshData} />
					<CSVDownloadLink data={this.state.data}>Export to CSV</CSVDownloadLink>
					<br />
					{this.state.loading ? <Spinner /> : null}
					<Snackbar
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						open={this.state.snackbar.open}
						onClose={this.closeSnackbarHandler}
						message={this.state.snackbar.message}
						action={this.closeButton}
						autoHideDuration={3000}
						/>
				</React.Fragment>
			</MuiThemeProvider>
			<CustomTable data={this.state.data} rowOnClick={this.rowOnClickHandler} />
			<br />
		</div>
	)

	submitHandler = (msg) => {
		if (msg)
			this.showSnackbarHandler(msg);
		
		this.refreshData();
	}

	caseScreenPage = (props) => <Casescreen 
		data={this.state.curdata}
		presubmitHandler={this.showSnackbarHandler} 
		submitHandler={this.submitHandler} 
		{...props} />
	
	showSnackbarHandler = (msg) => {
		this.setState({snackbar: {open: true, message: msg}});
	}

	closeSnackbarHandler = () => {
		this.setState({snackbar: {open: false}});
	}

	render() {
		const childProps = pick(this.props, ["isAuthenticated", "userHasAuthenticated", "refreshTokens", "tokens"]);

		return (
			<React.Fragment>
				<PrivateRoute path={this.props.match.url + "/case/:id"} component={this.caseScreenPage} props={childProps} />
				<Route component={this.tablePage} />
			</React.Fragment>
		)
	}
}

Homescreen.propTypes = {
	isAuthenticated: PropTypes.bool.isRequired,
	userHasAuthenticated: PropTypes.func.isRequired,
	history: PropTypes.any.isRequired,
	tokens: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
} 

export default Homescreen